import React from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { generate } from 'shortid'
import Layout from '../components/Layout'
import Img from '../components/AtomImg'
import Div from './../components/Div'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import H4 from '../components/H4'
import Paragraph from '../components/Paragraph'
import { css } from '@emotion/core'
import ds from '../theme'
import { Container } from '../components/styles/Layout.styles'
import { 
  styledNamePage,
  header,
} from '../components/styles/OurService.styles'
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons'

const socialIcons = css`
  a {
    color: rgb(217, 222, 227);
  }
  a:hover {
    opacity: 0.8;
    transition: opacity 0.4s;
  }
  a:not(:last-child) {
    margin-right: ${ds.space(3)}
  }
`

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledNamePage}>
      <Container>
        <Row
          flexDirection={['column']}
          css={header}
        >
          <Column
            width={[1]}
          >
            <H4>
              Nosso site está<br /> em manutenção
            </H4>
            <Div
              maxWidth={['90%', '60%', 300, 320]}
              style={{
                margin: '0 auto',
                marginBottom: 24
              }}
            >
              <Img
                fluid={data.image1.childImageSharp.fluid}
                loading='eager'
              />
            </Div>
            <Paragraph>Veio nos conhecer? Nosso site está em manutenção até às 22h de hoje, 02.07. Mas não desanime, você pode conferir nossas redes sociais por enquanto. Logo estamos de volta com um site ainda melhor pra você!</Paragraph>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 60
              }}
              css={socialIcons}
            >
              {[
                { icon: faLinkedinIn, link: 'https://www.linkedin.com/company/hostdime-brasil/' },
                { icon: faFacebookF, link: 'https://www.facebook.com/hostdimebr' },
                { icon: faInstagram, link: 'https://www.instagram.com/hostdimebr/' }
              ].map((social) => (
                <a
                  key={generate()}
                  href={social.link}
                  style={{
                    width: 50,
                    height: 50,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#878694',
                    borderRadius: '50%',
                    backgroundColor: '#fff',
                    fontSize: 20,
                    border: 'solid 0.8px #878694'
                  }}
                >
                  <FontAwesomeIcon icon={social.icon} />
                </a>
              ))}
            </div>
          </Column>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title='O melhor suporte do Brasil | HostDime Brasil'
        description='Suporte técnico e certificado, disponível 24x7x365 para uma assistência eficaz e atendimento personalizado às suas necessidades.'
      />
      <PageTemplate
        title='Nosso Atendimento'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "support/manutencao-tecnica.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
